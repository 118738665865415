import React from 'react'
// import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
// import styled from 'styled-components'
// import _ from 'lodash'

// import PageHeader from '../components/styles/PageHeader';
// import PageContainer from '../components/styles/PageContainer'

// const TagHeader = styled.div`
//   font-size: 14px;
//   font-weight: 600;
//   line-height: 15px;
//   padding: 20px 10px 0;
//   @media (max-width: ${({ theme }) => theme.mobile}) {
//     text-align: center;
//   }
//   text-transform: uppercase;
// `;

// const Content = styled.div`
//   .list {
//     min-height: 120px;
//   }
// `

class CatRoute extends React.Component {
  render() {
    // const posts = this.props.data.allMarkdownRemark.edges
    // const category = this.props.pageContext.category
    // const title = this.props.data.site.siteMetadata.title
    // const totalCount = this.props.data.allMarkdownRemark.totalCount
    return null;
  }
}

export default CatRoute

export const catPageQuery = graphql`
  query CatPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
